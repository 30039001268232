import React from "react"

import tzIds from "tz-ids";
//import Select from "react-select";
import moment from 'moment-timezone';
import Range from 'rc-slider/lib/Range';
import Slider from 'rc-slider/lib/Slider';
import 'rc-slider/assets/index.css';

class ConvertTimezone extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            proposedTimezone: {value:'Australia/Sydney', label: 'Sydney'},
        }
    }

    handle = (props) => {
        return ("nothing");
    }

    render() {
        const wrapperStyle = {
            //width: 400,
            marginBottom: 100,
        }

        var i;
        var marks={};
        for (i=0; i<24; i++){
            marks[i]=<div>
                {i}
                <div className="col-sm-1">
                    {moment().tz(this.state.proposedTimezone.value).add(i,'h').format('H')}
                </div>
            </div>;
        }

        var theirTime = moment().tz(this.state.proposedTimezone.value);

        return (


            <div className="row" style={wrapperStyle}>
                <Range 
                    min={0}
                    max={23}
                    defaultValue={[3,10]}
                    tipFormatter={
                        value => `${value}`
                    }
                    marks={marks}
                />
            </div>
        )
    }
}

export default ConvertTimezone