import React from "react"
import { graphql } from "gatsby"

import Mailto from "react-protected-mailto"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AboutShort from "../components/aboutshort"
import ConvertTimezone from "../components/converttimezone"
import ToMeet from "../components/tomeet"

class About extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="About" />
        <div className="col-lg-5 col-sm-10">
          <ToMeet/>
        </div>
      </Layout>
    )
  }
}

export default About

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`